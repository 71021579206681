
import theme from "../../utils/theme";
import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
`;

export const ScannerContainer = styled.div`
  margin-top: 1rem;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 500px;
  background-color: #f5f5f5;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VideoContainer = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  max-width: ${props => props.$isMobile ? "100%" : "500px"};
  height: ${props => props.$isMobile ? "300px" : "400px"};
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;

  section {
    width: 100% !important;
    height: 100% !important;
    
    video {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }
`;

export const StyledButton = styled.button`
  background-color: #1976d2;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 16px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #1565c0;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.5);
  }
`;

export const InstructionText = styled.p`
  font-size: 14px;
  color: #666666;
  margin-top: 16px;
  text-align: center;
`;


export const BackButton = styled.button`
  background-color: ${theme.darkBlue};;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;
