import React, { useEffect, useState, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { loginJudge } from "../../../utils/api";
import { useDispatch } from "react-redux";
import {
  setChallengeId,
  setId,
  setQrJwt,
  setUserName,
} from "../../../reducers/session";

import LoadingComponent from "../../shared/loading-screen-component";

type AuthGuardProps = {
  children: ReactNode;
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  mainMenu?: boolean;
};

const AuthGuard: React.FC<AuthGuardProps> = ({
  children,
  isAuthenticated,
  setIsAuthenticated,
  mainMenu,
}) => {
  const dispatch = useDispatch();
  const [isVerified, setIsVerified] = useState(isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuthentication = async () => {
      if (!isAuthenticated) {
        const company_pin = localStorage.getItem("company_pin");
        const user_name = localStorage.getItem("user_name");
        const jwt = localStorage.getItem("QrJwt"); 

        if (company_pin && user_name && jwt) {
          try {
            const data = await loginJudge(company_pin, user_name);
            dispatch(setChallengeId(company_pin))
            dispatch(setId(data.token));
            dispatch(setUserName(user_name));
            dispatch(setQrJwt(jwt)); 
            setIsAuthenticated(true);
            navigate("/home");
          } catch (error) {
            localStorage.removeItem("company_pin");
            localStorage.removeItem("user_name");
            if (mainMenu) {
              setIsVerified(true);
            } else {
              navigate("/logout");
            }
          }
        } else {
          localStorage.removeItem("company_pin");
          localStorage.removeItem("user_name");
          localStorage.removeItem("QrJwt"); 
          if (mainMenu) {
            setIsVerified(true);
          } else {
            navigate("/logout");
          }
        }
      } else {
        setIsVerified(true);
      }
    };
    verifyAuthentication();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  if (!isVerified) {
    return <LoadingComponent />;
  }

  return <>{children}</>;
};

export default AuthGuard;
