import React, { useState, useEffect, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../utils/types";
import { getProjects, API_BASE_ENDPOINT_URL } from "../../../../utils/api";
import { setJudgingOpenStatus } from "../../../../reducers/session";
import ErrorComponent from "../../../shared/error-screen-component";
import LoadingComponent from "../../../shared/loading-screen-component";
import {
  setCompletedProjects,
  setOverallScoresOfProjects,
  setPendingProjects,
} from "../../../../reducers/project";

interface LoadProjectsDataProps {
  children: ReactNode;
}

const LoadProjectsData: React.FC<LoadProjectsDataProps> = ({ children }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state: RootState) => state.session.id);
  const judgeName = useSelector((state: RootState) => state.session.userName);
  const challengeName = useSelector(
    (state: RootState) => state.session.challengeName
  );
  const results = useSelector((state: RootState) => state.session.results);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!API_BASE_ENDPOINT_URL) {
        const response = await getProjects(authToken);
        const projectList = response.projects;
        const isJudgingEnable = response.judging_enabled;

        // Use same processing logic as DEV/PROD mode
        const completedProjects = projectList
          .filter((project: any) => project.proj_mark) //Only projects with marks (completed projects)
          .map((project: any) => {
            const filteredNotes = project.proj_notes.filter(
              (note: any) => note.author === judgeName
            );

            const judgeNote =
              filteredNotes.length > 0
                ? filteredNotes[filteredNotes.length - 1]
                : null;
            return {
              id: project.proj_id,
              name: project.proj_name,
              score: project.proj_mark,
              // members: project.proj_members,
              $imageurl: "https://picsum.photos/200",
              description: project.proj_desc,
              devPostUrl: project.proj_devpost,
              notes: judgeNote ? judgeNote.note : "",
              allNotes: project.proj_notes,
            };
          });

        const pendingProjects = projectList
          .filter((project: any) => !project.proj_mark) //Only projects without marks (pending projects)
          .map((project: any) => {
            const filteredNotes = project.proj_notes.filter(
              (note: any) => note.author === judgeName
            );

            const judgeNote =
              filteredNotes.length > 0
                ? filteredNotes[filteredNotes.length - 1]
                : null;
            return {
              id: project.proj_id,
              name: project.proj_name,
              score: -1,
              // members: project.proj_members,
              $imageurl: "https://picsum.photos/200",
              description: project.proj_desc,
              devPostUrl: project.proj_devpost,
              notes: judgeNote ? judgeNote.note : [],
              allNotes: project.proj_notes,
            };
          });

        dispatch(setCompletedProjects(completedProjects));
        dispatch(setPendingProjects(pendingProjects));
        dispatch(setOverallScoresOfProjects(response.results));
        setLoading(false);
        return;
      }

      // DEV/PROD Mode: Fetch from Backend
      try {
        const response = await getProjects(authToken);

        if (response.errorMessage) {
          console.error("Error:", response.errorMessage);
          if (response.errorMessage.includes("judging is not yet enabled")) {
            dispatch(setJudgingOpenStatus(false));
          } else {
            setError(response.errorMessage);
          }
        } else {
          const projectList = response.projects;
          const isJudgingEnable = response.judging_enabled;
          dispatch(setCompletedProjects([]));
          dispatch(setPendingProjects([]));
          dispatch(setJudgingOpenStatus(isJudgingEnable));
          const completedProjects = projectList
            .filter((project: any) => project.proj_mark)
            .map((project: any) => {
              const filteredNotes = project.proj_notes.filter(
                (note: any) => note.author === judgeName
              );

              const judgeNote =
                filteredNotes.length > 0
                  ? filteredNotes[filteredNotes.length - 1]
                  : null;
              return {
                id: project.proj_id,
                name: project.proj_name,
                score: project.proj_mark,
                // members: project.proj_members,
                $imageurl: "https://picsum.photos/200",
                description: project.proj_desc,
                devPostUrl: project.proj_devpost,
                notes: judgeNote ? judgeNote.note : "",
                allNotes: project.proj_notes,
              };
            });
          const pendingProjects = projectList
            .filter((project: any) => !project.proj_mark)
            .map((project: any) => {
              const filteredNotes = project.proj_notes.filter(
                (note: any) => note.author === judgeName
              );

              const judgeNote =
                filteredNotes.length > 0
                  ? filteredNotes[filteredNotes.length - 1]
                  : null;
              return {
                id: project.proj_id,
                name: project.proj_name,
                score: -1,
                // members: project.proj_members,
                $imageurl: "https://picsum.photos/200",
                description: project.proj_desc,
                devPostUrl: project.proj_devpost,
                notes: judgeNote ? judgeNote.note : [],
                allNotes: project.proj_notes,
              };
            });
          dispatch(setCompletedProjects(completedProjects));
          dispatch(setPendingProjects(pendingProjects));

          const challengeResults = results.find((result: any) => {
            return result.challenge === challengeName;
          });

          if (!isJudgingEnable && challengeResults) {
            dispatch(setOverallScoresOfProjects(challengeResults.results));
          }
        }
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [authToken, challengeName, dispatch, judgeName, results]);

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent message={error} />;

  return <>{children}</>;
};

export default LoadProjectsData;
