export const cohereCriteria = [
  {
    categoryName: "Complexity",
    categoryQuestion:
      "Was Cohere a main component in project? Simple use, or complex use of Cohere/multiple endpoints?",
  },
  {
    categoryName: "Idea",
    categoryQuestion:
      "How unique is this project - something new, or a project we’ve seen before? Usable?",
  },
  {
    categoryName: "Completion",
    categoryQuestion: "How complete is the hack relative to the team's goal?",
  },
];

export const rbcCriteria = [
  {
    categoryName: "Technology",
    categoryQuestion: "How technically involved is the project?",
  },
  {
    categoryName: "Innovation",
    categoryQuestion: "How innovative and thoughtful is the project?",
  },
  {
    categoryName: "Design",
    categoryQuestion: "How easy and enjoyable is the user experience?",
  },
  {
    categoryName: "Complexity",
    categoryQuestion: "How complex is the project?",
  },
  {
    categoryName: "Theme (Perspective)",
    categoryQuestion: "How relevant is the project to this year's theme?",
  },
];

export const cseCriteria = [
  {
    categoryName: "Technology",
    categoryQuestion:
      "How technically involved is the project? Consider the innovative use of technologies and tools.",
  },
  {
    categoryName: "Learning",
    categoryQuestion:
      "How much of this project had the team never done before? Evaluate the extent to which the team ventured into uncharted territory.",
  },
  {
    categoryName: "Design",
    categoryQuestion:
      "How easy and enjoyable is the user experience? Consider the intuitiveness, aesthetics, and accessibility of the design.",
  },
  {
    categoryName: "Completion",
    categoryQuestion:
      "How complete is the hack relative to the team's goal? Consider the functionality and features implemented within 36 hours.",
  },
  {
    categoryName: "Theme (Github)",
    categoryQuestion: "How relevant is the project to this year's theme?",
  },
];

export const digileCriteria = [
  {
    categoryName: "Technology",
    categoryQuestion:
      "How technically involved is the project? Consider the innovative use of technologies and tools.",
  },
  {
    categoryName: "Learning",
    categoryQuestion:
      "How much of this project had the team never done before? Evaluate the extent to which the team ventured into uncharted territory.",
  },
  {
    categoryName: "Design",
    categoryQuestion:
      "How easy and enjoyable is the user experience? Consider the intuitiveness, aesthetics, and accessibility of the design.",
  },
  {
    categoryName: "Completion",
    categoryQuestion:
      "How complete is the hack relative to the team's goal? Consider the functionality and features implemented within 36 hours.",
  },
  {
    categoryName: "Theme (Business AI/ML)",
    categoryQuestion:
      "How relevant is this to your Best use of AI/ML criteria?",
  },
];

export const defaultCriteria = [
  {
    categoryName: "Design",
    categoryQuestion:
      "How well-designed is the project in terms of UI/UX, aesthetics, and accessibility?",
  },
  {
    categoryName: "Completion",
    categoryQuestion: "How complete and functional is the project?",
  },
  {
    categoryName: "Theme Relevance",
    categoryQuestion:
      "How well does the project embody the theme of '[THEME]'?",
  },
  {
    categoryName: "Idea & Originality",
    categoryQuestion: "How innovative, impactful, and unique is the project?",
  },
  {
    categoryName: "Practicality & Feasibility",
    categoryQuestion:
      "How realistic is the project for real-world implementation and scaling?",
  },
];

export const defaultBackdropImage = "/assets/challenge-backdrops/general.svg";

export const cohereBackdropImage = "/assets/challenge-backdrops/cohere.svg";

export const rbcBackdropImage = "/assets/challenge-backdrops/rbc.svg";
