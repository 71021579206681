import styled from "styled-components";
import { IDetectedBarcode, Scanner } from "@yudiel/react-qr-scanner";
import { MainContainer } from "../shared/nav-bar-component/index.styles";
import { BackButton, H1 } from "./index.styles";
import { Alert, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useMobileDetect } from '../../hooks/useMobileDetect';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/utils/types";
import { getProj } from "../../utils/api";

const ContentLayout = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
`;

const CenteredContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2rem;
    width: 100%;
`;

const QrScanComponent = () => {
    const navigate = useNavigate();
    const isMobile = useMobileDetect();
    const authToken = useSelector((state: RootState) => state.session.QrJwt);

    const [scanError, setScanError] = useState<string | null>(null);
    const [facingMode, setFacingMode] = useState<"environment" | "user">("environment");

    const handleScan = async (detectedCodes: IDetectedBarcode[]) => {
        if (detectedCodes && detectedCodes.length > 0) {
            const scannedData = detectedCodes[0].rawValue;
            console.log(JSON.stringify(authToken, null, 2));
            const response = await getProj(authToken, scannedData);
    
            const projectData = await response.json();
            if (!response.ok) {
                setScanError(projectData.message);
                return;
            }
            navigate(`/submissions/project/${projectData[0].proj_id}`);
        }
    };

    function handleError(error: unknown): void {
        if (error instanceof Error) {
            setScanError(error.message);
        } else {
            setScanError("An unexpected error occurred");
        }
    }

    const toggleFacingMode = () => {
        setFacingMode((prevMode: string) => (prevMode === "environment" ? "user" : "environment"));
    };

    return (
        <MainContainer>
            <ContentLayout>
                <BackButton 
                    onClick={() => navigate("/home")}
                    style={{
                        position: 'absolute',
                        top: '1rem',
                        left: '1rem'
                    }}
                >
                    Back to Main Menu
                </BackButton>
                
                <CenteredContent>
                    <H1>Project Scanner</H1>
                    
                    <Box
                        sx={{
                            marginTop: "1rem",
                            borderRadius: "12px",
                            overflow: "hidden",
                            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
                            width: "300px",
                            height: "300px",
                        }}
                    >
                        <Scanner
                            onScan={handleScan}
                            onError={handleError}
                            constraints={{facingMode: facingMode}}
                            scanDelay={300}
                            allowMultiple={false}
                            styles={{
                                container: {
                                    width: "100%",
                                },
                                video: {
                                    width: "100%",
                                    height: "100%",
                                },
                            }}
                        />
                    </Box>

                    {scanError && (
                        <Alert 
                            severity="error" 
                            sx={{ 
                                mt: 2, 
                                mb: 2,
                                position: "fixed",
                                top: "20px",
                                left: "50%",
                                transform: "translateX(-50%)",
                                zIndex: 1000,
                                width: "80%",
                            }}
                            onClose={() => setScanError(null)}
                        >
                            {scanError === 'Hacker has not RSVP\'d for the event'
                                ? "This hacker hasn't RSVP'd for the event yet"
                                : scanError}
                        </Alert>
                    )}

                    <Button 
                        onClick={toggleFacingMode} 
                        variant="contained" 
                        sx={{ mt: 2 }}
                    >
                        Switch Camera
                    </Button>
                    
                    <Typography 
                        variant="body2" 
                        color="#666666" 
                        sx={{ mt: 2 }}
                    >
                        Point your camera at a QR code to scan.
                    </Typography>
                </CenteredContent>
            </ContentLayout>
        </MainContainer>
    );
};

export default QrScanComponent;