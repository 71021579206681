import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setId, setQrJwt, setUserName } from "../../reducers/session";
import { NavigateButton } from "../shared/buttons/index.styles";
import React, { useState, useEffect } from "react";
import { Navbar } from "../shared/nav-bar-component";
import CustomDropdown from "./dropdown-component";
import {
  Container,
  TextInput,
  ErrorMessage,
  TitleContainer,
  ButtonFooterContainer,
  AddUserButtonContainer,
  AddUserButton,
  InfoTextContainer,
  BoldInfoText,
} from "./index.styles";
import { RootState } from "../../utils/types";
import { registerJudge, loginJudge, loginQrJwt } from "../../utils/api";
import GenericBackground from "../../components/shared/generic-background";
import BricolageGrotesque from "../../components/shared/fonts/bricolage-grotesque";
import theme from "../../utils/theme";
import Inter from "../../components/shared/fonts/inter";
import { API_BASE_ENDPOINT_URL } from "../../utils/api"; // Import the base URL

type UserSelectComponentProps = {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

const UserSelectComponent: React.FC<UserSelectComponentProps> = ({
  setIsAuthenticated,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currUser, setCurrUser] = useState<string | null>(null);
  const [newUserName, setNewUserName] = useState<string>("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const company_pin = useSelector(
    (state: RootState) => state.session.challengeId
  );
  const challenge_name = useSelector((state: RootState) => {
    return state.session.challengeName;
  });

  const nameNotListedOption = "My name is not listed";

  const judgesList = useSelector((state: RootState) => {
    return state.session.usersList;
  });
  const [judges, setJudges] = useState([...judgesList, nameNotListedOption]);

  useEffect(() => {
    const updatedJudgesList = judgesList.filter(
      (judge) => judge !== nameNotListedOption
    );
    setJudges([...updatedJudgesList, nameNotListedOption]);
  }, [judgesList]);

  const handleDropdownSelect = async (selectUser: any) => {
    setCurrUser(selectUser);
    setShowAddUser(selectUser === nameNotListedOption);
  };

  const handleTextInputChange = (event: any) => {
    if (showAddUser) {
      setNewUserName(event.target.value);
    }
  };

  const handleContinue = async () => {
    setErrorMessage("");
    setIsLoading(true);

    if (currUser) {
      setIsAuthenticated(true);

      // Skip backend calls if in FE mode
      if (!API_BASE_ENDPOINT_URL) {
        // FE mode: URL is an empty string
        dispatch(setId("mock-token"));
        dispatch(setUserName(currUser));
        localStorage.setItem("company_pin", company_pin || "mock-pin");
        localStorage.setItem("user_name", currUser);
        navigate("/home");
        setIsLoading(false);
        return;
      }

      const QrJwt  = await loginQrJwt(); 
      dispatch(setQrJwt(QrJwt.jwt)); 
      localStorage.setItem("QrJwt", QrJwt.jwt); 

      if (showAddUser) {
        if (newUserName) {
          try {
            const data = await registerJudge(company_pin, newUserName);
            dispatch(setId(data.token));
            dispatch(setUserName(data.data.judges_name));

            localStorage.setItem("company_pin", company_pin);
            localStorage.setItem("user_name", data.data.judges_name);
            navigate("/home");
          } catch (error: any) {
            setErrorMessage(error.message);
          } finally {
            setIsLoading(false);
          }
        } else {
          setErrorMessage("Invalid name");
          setIsLoading(false);
        }
      } else {
        try {
          const data = await loginJudge(company_pin, currUser);
          dispatch(setId(data.token));
          dispatch(setUserName(currUser));
          localStorage.setItem("company_pin", company_pin);
          localStorage.setItem("user_name", currUser);
          navigate("/home");
        } catch (error: any) {
          setErrorMessage(error.message);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      setErrorMessage("Please select or create a user.");
      setIsLoading(false);
    }
  };

  const titleFontSize = {
    fontSize: "30px",
    letterSpacing: "0.0rem",
    textAlign: "left",
  };
  const helperFontSize = {
    fontSize: "16px",
    textAlign: "left",
  };

  return (
    <>
      <Navbar popStack="/login" hamburger={false} showbutton={true} />
      <GenericBackground />
      <Container>
        {/*<Image src={imagebackDrop} alt="Placeholder" />*/}
        <TitleContainer>
          <BricolageGrotesque
            style={{
              ...titleFontSize,
              color: theme.black,
              lineHeight: "1.1",
              whiteSpace: "pre-line",
              margin: "20px 0",
            }}
          >
            Select your name
          </BricolageGrotesque>
          <Inter
            style={{
              ...helperFontSize,
            }}
          >
            Please select your name from the list below.
          </Inter>
          <CustomDropdown options={judges} onSelect={handleDropdownSelect} />
          <TextInput
            type="text"
            placeholder="Please enter your full name here!"
            onChange={handleTextInputChange}
            value={
              newUserName
            }
            className={showAddUser ? "show" : ""}
          />
          {showAddUser && (
            <AddUserButtonContainer>
              <AddUserButton
                onClick={handleContinue}
                disabled={!newUserName || isLoading}
              >
                {isLoading ? "Adding..." : "Add User"}
              </AddUserButton>
            </AddUserButtonContainer>
          )}
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <InfoTextContainer>
            Based on the PIN you have entered you are from sponsor group
            <BoldInfoText> {challenge_name}</BoldInfoText>, if this is incorrect
            please let an organizer know as soon as possible.
          </InfoTextContainer>
        </TitleContainer>

        <ButtonFooterContainer>
          <NavigateButton
            onClick={() => handleContinue()}
            disabled={
              isLoading ||
              currUser === null ||
              currUser === "MY NAME IS NOT LISTED"
            }
          >
            {isLoading ? "Loading..." : "Continue"}
          </NavigateButton>
        </ButtonFooterContainer>
      </Container>
    </>
  );
};
export default UserSelectComponent;
