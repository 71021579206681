import React from "react";
import CardButton from "../shared/nav-button-group-component/nav-button";
import ButtonContainer from "../shared/nav-button-group-component";
import Inter from "../shared/fonts/inter";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/types";
import { Navbar } from "../shared/nav-bar-component";
import {
  Prompt,
  QRCodeButton,
  ProjectsNavigationContainer,
  ProjectNavButton,
  IllustrationOverlay,
  GridContainer,
} from "./index.styles";
import ModalDialog from "../shared/modal-dialog-component";
import { MainContainer } from "../shared/nav-bar-component/index.styles";
import BricolageGrotesque from "../../components/shared/fonts/bricolage-grotesque";
import theme from "../../utils/theme";
import { MarkedProjectsComponent } from "../../components/dashboard-component/marked-projects-component";
import { QuickNavComponent } from "../../components/dashboard-component/quick-nav-component";
import { useMobileDetect } from "../../hooks/useMobileDetect";

type MainPageProps = {
  name?: string;
};

const scheduleLink =
  "https://docs.google.com/spreadsheets/d/1FzvHXdbQRQOXMLvBBmey_p0sMbiIOQKkx-gnPgBLvIY/edit?usp=sharing";
export const handleScheduleClick = () => {
  window.open(scheduleLink);
};

const MainPageComponent: React.FC<MainPageProps> = () => {
  const isMobile = useMobileDetect();
  const userName = useSelector((state: RootState) => state.session.userName);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const resultsDisabled = !useSelector(
    (state: RootState) => state.session.resultsOpen,
  );
  const judgingDisabled = !useSelector(
    (state: RootState) => state.session.judgingOpen,
  );

  const dialogMessage = "Are you sure you want to log out?";

  const navigate = useNavigate();

  const titleStyle = {
    fontSize: "30px",
    letterSpacing: "0.0rem",
    textAlign: "left",
    color: theme.black,
    lineHeight: "1.1",
    whiteSpace: "pre-line",
    margin: "20px 25px",
    marginBottom: "5px",
  };

  const headerStyle = {
    ...titleStyle,
    fontSize: "25px",
  };

  const helperFontSize = {
    fontSize: "14px",
    textAlign: "left",
    color: "#7D7B7A",
    margin: "20px 25px",
    marginTop: "5px",
  };

  const projectsInterStyle = {
    fontSize: "14px",
    margin: 0,
    padding: 0,
  };

  const qrScannerInterStyle = {
    fontSize: "14px",
    margin: 0,
    padding: 0,
  };

  const navBarTitle = "Home";
  const title = `Welcome, ${userName}!`;

  const onBackClick = () => {
    setDialogOpen(true);
  };

  return (
    <div>
      <Navbar
        title={navBarTitle}
        hamburger={true}
        showbutton={true}
        onBackClick={onBackClick}
        isHomepage={true}
      />
      <MainContainer backgroundColor={"#fff"}>
        <BricolageGrotesque style={titleStyle}>{title}</BricolageGrotesque>
        <Inter style={helperFontSize}>
          This is your portal for all things judging at UofTHacks 12!{" "}
        </Inter>

        <ProjectsNavigationContainer>
          <IllustrationOverlay
            src={"/assets/illustrations/projects.svg"}
            alt={"projects"}
          />
          <ProjectNavButton onClick={() => navigate("/submissions/pending")}>
            <Inter style={projectsInterStyle}>Projects</Inter>
            <img src={"/assets/icons/arrowRight.svg"} alt={"right arrow"} />
          </ProjectNavButton>
        </ProjectsNavigationContainer>

        <BricolageGrotesque style={headerStyle}>
          Marked projects
        </BricolageGrotesque>

        <MarkedProjectsComponent />

        <GridContainer isMobile={isMobile}>
          <QuickNavComponent
            svgSource={"/assets/illustrations/schedule.svg"}
            pageName={"Schedule"}
            helperPrompt={"Know where to be & when"}
            onClick={handleScheduleClick}
          />
          <QuickNavComponent
            svgSource={"/assets/illustrations/judging.svg"}
            pageName={"Judging Guide"}
            helperPrompt={"View criteria "}
            onClick={() => navigate("/judging-criteria")}
          />
          <QuickNavComponent
            svgSource={"/assets/illustrations/judging.svg"}
            pageName={"Results"}
            helperPrompt={"View results "}
            onClick={() => navigate("/results")}
          />
        </GridContainer>

        <ButtonContainer>
          {judgingDisabled && <Prompt>The judging period is closed.</Prompt>}
          {resultsDisabled && (
            <Prompt>
              Results are disabled until AFTER judging is completed.
            </Prompt>
          )}
        </ButtonContainer>
      </MainContainer>
      <QRCodeButton onClick={() => navigate("/scan")}>
        <img
          src={"/assets/icons/qrCode.svg"}
          alt="QR Code Icon"
          style={{ width: "20px" }}
        />
        <Inter style={qrScannerInterStyle}>Scan QR Code</Inter>
      </QRCodeButton>
      <ModalDialog
        $isopen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => {
          setDialogOpen(false);
          navigate("/logout/");
        }}
        message={dialogMessage}
      />
    </div>
  );
};

export default MainPageComponent;
