import Inter from "../fonts/inter";
import { useState, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import BackIcon from "./back-icon";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import {
  NavLink,
  NavLinkItem,
  NavbarContainer,
  MobileMenu,
  MobileNavLinkItem,
  BackButton,
  HamburgerMenu,
  SidePanel,
  Overlay,
  CloseButtonContainer,
  LogoutButton,
  NavbarWrapper,
} from "./index.styles";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "@/utils/types";
import BricolageGrotesque from "../fonts/bricolage-grotesque";
import { handleScheduleClick } from "../../../components/dashboard-component";

type NavbarProps = {
  title?: string;
  popStack?: string;
  hamburger?: boolean;
  favourite?: boolean | undefined;
  showbutton?: boolean;
  onBackClick?: () => void;
  onFavouriteClick?: () => void;
  isLogOut?: boolean;
  isHomepage?: boolean;
  children?: ReactNode;
};

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  transform: translateX(-10px);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .not-favourite-icon {
    transform: scale(1.7); /* Scale and shift left by 10px */
  }
`;

const Navbar: React.FC<NavbarProps> = ({
  title,
  popStack,
  hamburger,
  favourite,
  onBackClick,
  onFavouriteClick,
  showbutton,
  isLogOut,
  isHomepage,
  children,
}) => {
  const userName = useSelector((state: RootState) => state.session.userName);
  const companyName = useSelector(
    (state: RootState) => state.session.challengeName,
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const $ismobile = useMobileDetect();

  const [mobileMenuNode, setMobileMenuNode] = useState<HTMLSpanElement | null>(
    null,
  );

  const [hamburgerNode, setHamburgerNode] = useState<HTMLDivElement | null>(
    null,
  );
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { primaryColor, secondaryColor } = isHomepage
    ? { primaryColor: "#0a165a", secondaryColor: "#fff" }
    : { primaryColor: "#fff", secondaryColor: "#0a165a" };

  const favouriteIcon = "/assets/icons/favourite.svg";
  const notFavouriteIcon = "/assets/icons/notFavourite.svg";

  const fontSize = {
    fontSize: $ismobile ? "18px" : "25px",
    color: secondaryColor,
    letterSpacing: "0rem",
    fontWeight: "900",
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    const handleClickOutside = (event: any) => {
      if (
        isMenuOpen &&
        mobileMenuNode &&
        !mobileMenuNode.contains(event.target) &&
        !hamburgerNode?.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMenuOpen, hamburgerNode, mobileMenuNode]);

  const MobileInterStyle = () => ({
    fontSize: "15px",
    color: secondaryColor,
  });

  const InterSignOutInterStyle = () => ({
    fontSize: "15px",
    padding: "0px",
    margin: "0px",
    color: primaryColor,
  });

  const SidePanelUserNameStyle = {
    fontSize: "25px",
    letterSpacing: "0.0rem",
    color: secondaryColor,
    lineHeight: "1.1",
    whiteSpace: "pre-line",
    textAlign: "left",
    marginLeft: "20px",
    marginBottom: "0px",
  };

  const SidePanelCompanyNameStyle = {
    fontSize: "20px",
    letterSpacing: "0.0rem",
    color: secondaryColor,
    lineHeight: "1.1",
    whiteSpace: "pre-line",
    textAlign: "left",
    marginLeft: "20px",
  };

  const navigate = useNavigate();

  const navbarStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: title ? primaryColor : undefined,
  };

  return (
    <>
      <NavbarWrapper>
        <NavbarContainer style={navbarStyle} $genericVariant={Boolean(title)}>
          <div>
            {/* <Link to={popStack ? popStack : "/home"}>
            <BackButton>
              <Inter style={fontSize}>{"<"}</Inter>
            </BackButton>
          </Link> */}
            {!isHomepage && (
              <BackButton
                $genericVariant={Boolean(title)}
                $showbutton={showbutton}
                onClick={() => {
                  if (isLogOut) {
                    navigate("/logout");
                  } else if (onBackClick) {
                    onBackClick();
                  } else {
                    navigate(popStack ? popStack : "/home");
                  }
                }}
              >
                <BackIcon />
              </BackButton>
            )}
            {favourite === undefined && (
              <div>
                <HamburgerMenu
                  ref={(node) => setHamburgerNode(node)}
                  $showhamburger={hamburger}
                  onClick={toggleMenu}
                  color={secondaryColor}
                >
                  <img src={"/assets/icons/hamburger.svg"} alt="hamburger" />
                </HamburgerMenu>
              </div>
            )}
          </div>

          <div>
            <Inter style={fontSize}>{title}</Inter>
          </div>

          {favourite !== undefined && (
            <IconContainer onClick={onFavouriteClick}>
              {favourite === true ? (
                <img src={favouriteIcon} alt="Favourite Icon" />
              ) : (
                <img
                  src={notFavouriteIcon}
                  alt="Not Favourite Icon"
                  className="not-favourite-icon"
                />
              )}
            </IconContainer>
          )}
        </NavbarContainer>
        {children}
      </NavbarWrapper>

      <Overlay open={isMenuOpen} />

      <span ref={(node) => setMobileMenuNode(node)}>
        <SidePanel open={isMenuOpen} backgroundColor={primaryColor}>
          <CloseButtonContainer onClick={() => setIsMenuOpen(false)}>
            <img src={"/assets/icons/closeMenu.svg"} alt="Close Menu" />
          </CloseButtonContainer>
          <BricolageGrotesque style={SidePanelUserNameStyle}>
            {userName}
          </BricolageGrotesque>
          <Inter style={SidePanelCompanyNameStyle}>{companyName}</Inter>
          <MobileNavLinkItem>
            <NavLinkItem>
              <NavLink onClick={() => navigate("/submissions/pending")}>
                <img src={"/assets/icons/projectNav.svg"} alt="Project Icon" />
                <Inter style={MobileInterStyle()}>Projects</Inter>
              </NavLink>
            </NavLinkItem>
          </MobileNavLinkItem>
          <MobileNavLinkItem>
            <NavLinkItem>
              <NavLink onClick={handleScheduleClick}>
                <img
                  src={"/assets/icons/scheduleNav.svg"}
                  alt="Schedule Icon"
                />
                <Inter style={MobileInterStyle()}>Schedule</Inter>
              </NavLink>
            </NavLinkItem>
          </MobileNavLinkItem>
          <MobileNavLinkItem>
            <NavLinkItem>
              <NavLink onClick={() => navigate("/judging-criteria")}>
                <img
                  src={"/assets/icons/judgeGuideNav.svg"}
                  alt="Judge Guide Icon"
                />
                <Inter style={MobileInterStyle()}>Judging Criteria</Inter>
              </NavLink>
            </NavLinkItem>
          </MobileNavLinkItem>
          {/* <MobileNavLinkItem>
            <NavLinkItem>
              <NavLink onClick={() => navigate("/home")}>
                <img src={"/assets/icons/supportNav.svg"} alt="Support Icon" />
                <Inter style={MobileInterStyle()}>Support</Inter>
              </NavLink>
            </NavLinkItem>
          </MobileNavLinkItem> */}
          <LogoutButton
            onClick={
              onBackClick
                ? () => {
                  setIsMenuOpen(false);
                  onBackClick();
                }
                : () => navigate("/logout")
            }
          >
            <img src={"/assets/icons/buttonSignOut.svg"} alt="Sign Out Icon" />
            <Inter style={InterSignOutInterStyle()}>Sign Out</Inter>
          </LogoutButton>
        </SidePanel>
      </span>
    </>
  );
};

export { Navbar, NavbarContainer };
